#utility-navigation {
    min-height: 90px;
    max-height: 90px;
    margin-right: -1px;
    right: 0;
    position: absolute;
    width: 140px;
  .language-selector {
    width: 60px;
  }
}