
#sub-navigation {
  margin-left: 455px;
  min-height: 90px;
  max-height: 90px;
  margin-top: .8rem;
  margin-right: 0;
  // position: absolute;
  width: calc(100% - 470px);
  z-index: 9;

  .icon-tabler {
    color: white;
    width: 42px;
    height: 42px;
    stroke-width: 2.5;
  }
}

.nav-item {
  text-transform: uppercase;
  &.dropdown {
    .dropdown-item {
      background: none;
      background-image: none;

    }
    &.dropdown-mega {
      position: static;

      // TODO: add style for mobile viewport
      .dropdown-menu {
        width: 90%;
        top: auto;
        left: 5%;
      }
    }
  }
}

.navbar-toggler {
  border: none;
  padding: 0;
  outline: none;

  &:focus {
    box-shadow: none;
  }

  .hamburger-toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    z-index: 11;

    .hamburger {
      position: absolute;
      transform: translate(-50%, -50%) rotate(0deg);
      left: 50%;
      top: 50%;
      width: 50%;
      height: 50%;
      pointer-events: none;

      span {
        width: 100%;
        height: 4px;
        position: absolute;
        background: #333;
        z-index: 1;
        transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
          all 0.2s ease-in-out;
        left: 0px;

        &:first-child {
          top: 10%;
          transform-origin: 50% 50%;
          transform: translate(0% -50%) !important;
        }

        &:nth-child(2) {
          top: 50%;
          transform: translate(0, -50%);
        }

        &:last-child {
          left: 0px;
          top: auto;
          bottom: 10%;
          transform-origin: 50% 50%;
        }

      }
      
      &.active {
        span {
          position: absolute;
          margin: 0;

          &:first-child {
            top: 45%;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            left: 50%;
            width: 0px;
          }

          &:last-child {
            top: 45%;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
