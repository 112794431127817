// TODO: add better mobile behaviour & css variables
#main-navigation {
  min-height: 90px;
  margin: .8rem;
  padding: 9px 6px;
  transition: right 0.2s ease-in-out, bottom 0.2s ease-in-out;
  left: 0;
  bottom: calc(100% - 115px);
  right: calc(100% - 455px);
  z-index: 1110;

  &.fullscreenMenu {
    left: 0;
    bottom: 0;
    right: 0;

    #main-navigation-content {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }

  &:not(.fullscreenMenu) {
    #main-navigation-content {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }

  &.popoutSearch {
    left: 0;
    right: 0;
  }

  input#search-input {
    &::placeholder {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="gray" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>');
      background-position: center left 28px;
      background-repeat: no-repeat;
    }
    &:focus::placeholder {
      background-image: none;
    }
  }

  #burger-menu {
    cursor: pointer;

    .main-nav-icon {
      width: 40px;
      height: 30px;
      margin: 20px 15px 15px;
      position: relative;
      display: inline-block;

      span {
        background-color: var(--bs-light);
        position: absolute;
        border-radius: 2px;
        transition: 0.1s ease-in-out;
        width: 100%;
        height: 4px;
        transition-duration: 300ms;

        &:nth-child(1) {
          top: 0px;
          left: 0px;
        }

        &:nth-child(2) {
          top: 13px;
          left: 0px;
          opacity: 1;
        }

        &:nth-child(3) {
          bottom: 0px;
          left: 0px;
        }
      }
    }

    &:not(.open):hover span {
      &:nth-child(1) {
        transform: rotate(-3deg) scaleY(1.1);
      }

      &:nth-child(2) {
        transform: rotate(3deg) scaleY(1.1);
      }

      &:nth-child(3) {
        transform: rotate(-4deg) scaleY(1.1);
      }
    }

    &.open span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 13px;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 13px;
      }
    }
  }
}

.navigation-sidebar {
  flex: 0 0 455px;
}