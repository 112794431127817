.background-blur {
  background-color: rgba(var(--#{$prefix}white-rgb), 0.8);
  backdrop-filter: saturate(200%) blur(40px);
}

.funky-gradient {
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  background-image: radial-gradient(18% 28% at 18% 71%, #ffffff59 6%, #073aff00 100%),
    radial-gradient(70% 53% at 36% 76%, #54a8b7ff 0%, #1036c521 100%),
    radial-gradient(42% 53% at 15% 94%, #ffffffff 7%, #073aff00 100%),
    radial-gradient(42% 53% at 34% 72%, #ffffffff 7%, #073aff00 100%),
    radial-gradient(18% 28% at 35% 87%, #ffffffff 7%, #073aff00 100%),
    radial-gradient(31% 43% at 7% 98%, #ffffffff 24%, #073aff00 100%),
    radial-gradient(21% 37% at 72% 23%, #6dffed9c 24%, #073aff00 100%),
    radial-gradient(35% 56% at 91% 74%, #8a4ffff5 9%, #073aff00 100%),
    radial-gradient(74% 86% at 67% 38%, #6dccfff5 24%, #073aff00 100%),
    linear-gradient(125deg, #4eb5ffff 1%, #fc0000ff 100%);
}
