//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Custom Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "fonts";

//---------------------------------------------
//
//  Partially loading Bootstrap's components
//
//    1.1. Banner
//    1.2. Functions
//    1.3. Variables
//    1.4. Maps + Mixins
//    1.5. Root + Reboot
//    1.6. Type
//    1.7. Components
//    1.8. Helpers & Mixins for Breakpoints + Print
//
//---------------------------------------------

//---------------------------------------------
// 1.1. Banner
//---------------------------------------------

@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");

//---------------------------------------------
// 1.2. Functions
//---------------------------------------------

@import "~bootstrap/scss/functions";

//---------------------------------------------
// 1.3. Variables
//---------------------------------------------

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

@import "variables";
// @import "~bootstrap/scss/variables";

//---------------------------------------------
// 1.4. Maps + Mixins
//---------------------------------------------

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "mixins/effects";
@import "utils";
//---------------------------------------------
// 1.5. Root + Reboot
//---------------------------------------------

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";

//---------------------------------------------
// 1.6. Type
//---------------------------------------------

@import "~bootstrap/scss/type";
@import "typography";
//---------------------------------------------
// 1.7. Components
//---------------------------------------------

@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

//---------------------------------------------
// 1.8. Helpers & Mixins for Breakpoints + Print
//---------------------------------------------

@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/mainmenu";
@import "components/submenu";
@import "components/utilitymenu";
@import "components/supportmenu";
@import "components/header";
@import "sfx";
@import "buttons";
@import "colors";
@import "pagination";
@import "animate.css";