//---------------------------------------------
//
//  Typography
//
//  
//
//---------------------------------------------

* { //TODO: add variable font parameters to variables
  --#{$prefix}variable-font-default: "wdth" 135, "wght" 400;
  letter-spacing: .0125rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %heading;
  font-variation-settings: "wdth" 125, "wght" 400;
}

@each $display, $font-size in $display-font-sizes {
  .display-#{$display} {
    font-variation-settings: "wdth" 150, "wght" 600;
  }
  .display-gradient-#{$display} {
    font-size: $font-size;
    font-variation-settings: "wdth" 150, "wght" 600;
    @include lin-gradient(135deg, $primary, $blue-400);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.nav-link {
  font-variation-settings: var(--#{$prefix}variable-font-default);
}